<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" :openTopMenu="openTopMenu"/>

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover"/>

    <dashboard-core-view
        :tourOn="tourOn"
        :saasFeatures="saasFeatures"
        :enterpriseFeatures="enterpriseFeatures"
        :selectedTab="selectedTab"
        @beginTour="beginTour"
    />

    <v-tour name="saasTour" :steps="tourSteps" :options="{ highlight: true }" :callbacks="tourCallbacks"></v-tour>
  </v-app>
</template>

<script>
import axios            from 'axios';
import globalMixins               from "../../mixins/globalMixins";
import { mapMutations, mapState } from "vuex";

export default {
  name: 'DashboardIndex',

  mixins: [ globalMixins ],

  components: {
    DashboardCoreAppBar: () => import('./components/template/AppBar'),
    DashboardCoreDrawer: () => import('./components/template/Drawer'),
    DashboardCoreView: () => import('./components/template/View'),
  },

  data: function () {
    const _self = this;

    return {
      selectedTab: null,
      tourOn: false,
      openTopMenu: false,
      expandOnHover: false,
      redirectPaymentMethods: false,
      saasFeatures: [
        {
          icon: 'mdi-monitor-dashboard',
          text: 'Hosted M360 Console'
        },
        {
          icon: 'mdi-domain',
          text: 'Single Project'
        },
        {
          icon: 'mdi-cloud',
          text: 'M360 Gateway deployable on any cloud provider'
        },
        {
          icon: 'mdi-license',
          text: 'One License per Project'
        },
        {
          icon: 'mdi-earth',
          text: 'Unlimited Environments'
        },
        {
          icon: 'mdi-api',
          text: 'Unlimited Microservices'
        },
        {
          icon: 'mdi-api',
          text: 'Support for all Web technologies'
        },
        {
          icon: 'mdi-cloud',
          text: 'Integrates with any cloud provider'
        },

      ],
      enterpriseFeatures: [
        {
          icon: 'mdi-monitor-dashboard',
          text: 'Downloadable M360 Console'
        },
        {
          icon: 'mdi-domain',
          text: 'Unlimited Projects'
        },
        {
          icon: 'mdi-cloud',
          text: 'M360 Suite Deployable on any cloud provider'
        },
        {
          icon: 'mdi-license',
          text: 'One License for unlimited Projects'
        },
        {
          icon: 'mdi-earth',
          text: 'Unlimited Environments'
        },
        {
          icon: 'mdi-api',
          text: 'Unlimited Microservices'
        },
        {
          icon: 'mdi-api',
          text: 'Support for all Web technologies'
        },
        {
          icon: 'mdi-cloud',
          text: 'Integrates with any cloud provider'
        },
      ],

      tourSteps: [
        {
          target: '#v-step-0',
          content: `When you login we provide you with a quick menu to access your M360API Projects.`,
          params: {
            placement: 'top',
            enableScrolling: (this.$screen.touch),
          },
          offset: (this.$screen.touch) ? -200: null,
          before: type => new Promise( ( resolve ) => {
            this.selectedTab = 0;
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#v-step-1',
          content: 'From this card, you can directly access your M360API SAAS Projects.',
          params: {
            placement: 'top',
            enableScrolling: (this.$screen.touch),
          },
          offset: (this.$screen.touch) ? -200: null,
          before: type => new Promise( ( resolve ) => {
            this.selectedTab = 0;
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#v-step-2',
          content: 'And from this card, you can directly access your M360API Enterprise Project.',
          params: {
            placement: 'top',
            enableScrolling: (this.$screen.touch),
          },
          offset: (this.$screen.touch) ? -200: null,
          before: type => new Promise( ( resolve ) => {
            this.selectedTab = 0;
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#v-step-3',
          content: 'You can also follow up on our Product News and stay up to speed about the latest releases and updates that CorsairM360 publishes.',
          params: {
            placement: 'top',
            enableScrolling: (this.$screen.touch)
          },
          offset: (this.$screen.touch) ? -200: null,
          before: type => new Promise( ( resolve ) => {
            this.selectedTab = 1;
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#v-step-4',
          content: 'You can review the Case Studies of our top and favorite clients who have used our product and see how we solved their problems.',
          params: {
            placement: 'top',
            enableScrolling: (this.$screen.touch)
          },
          offset: (this.$screen.touch) ? -200: null,
          before: type => new Promise( ( resolve ) => {
            this.selectedTab = 2;
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#a-step-0',  // We're using document.querySelector() under the hood
          content: `The Organization Menu is located in the top right corner of the screen. It grants you access to the global modules where you can manage your users, update your credit card information, browse your orders and download reports.`,
          before: type => new Promise( ( resolve ) => {
            if(_self.openTopMenu) {
              _self.openTopMenu = false;
              _self.$forceUpdate();
            }
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#a-step-01',
          content: `The Accounts Module allows you to modify your personal account settings including profile, email and password information.`,
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'left'
          },
          before: type => new Promise( ( resolve ) => {
            _self.openTopMenu = true;
            _self.$forceUpdate();
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#a-step-02',
          content: `The Billing Module is where you can browse your previous orders and export them as pdf copies.`,
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'left'
          }
        },
        {
          target: '#a-step-03',
          content: `The Payment Module is where you should start, here you enter your credit card information and everytime you create a project, this credit card is charged.`,
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'left'
          }
        },
        {
          target: '#a-step-04',
          content: `The Users Module enable you to invite additional users and give them administrator access to help you manage your projects`,
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'left'
          },
          before: type => new Promise( ( resolve ) => {
            this.setDrawer(false)
            
            if(!_self.openTopMenu) {
              _self.openTopMenu = true;
              _self.$forceUpdate();
            }
            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: (_self.$screen.touch) ? '#d-step-2' : '#a-step-1',
          content: 'We provided you with a quick link to our official documentation space where you can read all the technical details of our product and its components.',
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'right'
          },
          before: type => new Promise( ( resolve ) => {
            _self.openTopMenu = false;
            this.drawer = true;
            _self.$forceUpdate();            

            setTimeout( () => {
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: (_self.$screen.touch) ? '#d-step-3' : '#a-step-2',
          content: 'You can reach our Support Center via this link and engage them for any assistance you need.',
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'right'
          },
          before: type => new Promise( ( resolve ) => {
            setTimeout( () => {
              _self.drawer = true;
              _self.$forceUpdate();
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: (_self.$screen.touch) ? '#d-step-4' : '#a-step-3',
          content: 'In addition, you can also create tickets and follow up on their status. These tickets are sent to our Support Agents, our Quality Engineers for assessment.',
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'right'
          },
          before: type => new Promise( ( resolve ) => {
            setTimeout( () => {
              _self.drawer = true;
              _self.$forceUpdate();
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#d-step-0',  // We're using document.querySelector() under the hood
          content: `Here's where you can browse and manage all the SAAS Projects you have created so far.`,
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'right'
          },
          before: type => new Promise( ( resolve ) => {
            setTimeout( () => {
              _self.drawer = true;
              _self.$forceUpdate();
              resolve( true )
            }, 500 );
          } )
        },
        {
          target: '#d-step-1',
          content: `Finally, here's where you can create and manage your Enterprise Project and its license`,
          params: {
            placement: (this.$screen.touch) ? 'bottom' : 'right'
          },
          before: type => new Promise( ( resolve ) => {
            setTimeout( () => {
              _self.drawer = true;
              _self.$forceUpdate();
              resolve( true )
            }, 500 );
          } )
        }
      ],
      tourCallbacks: {}
    }
  },

  computed: {
    drawer: {
        get() {
          return this.$store.state.drawer
        },
        set(val) {
          this.$store.commit('SET_DRAWER', val)
        },
      }
  },

  updated() {
    const _self = this;
    const user = this.$store.getters[ 'auth/getUser' ];
    const acl = this.$store.getters[ 'auth/getACL' ];
    if ( !user || !acl ) {
      this.getSendData( {
                          'url': '/user',
                          'method': 'get'
                        } ).then( response => {
        this.$store.dispatch( 'auth/setUserAndACL', response );
      } ).catch( error => {
        handleErrorResponse( error );
      } );
    }

    function handleErrorResponse( error ) {
      _self.$store.dispatch( 'auth/clear' );
      if ( _self.$router.currentRoute.name !== 'Login' ) {
        _self.$router.push( { name: 'Login' } );

        setTimeout( () => {
          for ( let code in error ) {
            let oneError = error[ code ];
            _self.pushMessage( {
                                 'type': 'error',
                                 'title': `Error: ${ oneError.code }`,
                                 'text': oneError.message.toString()
                               } );
          }
        }, 500 );
      }
    }
  },

  methods: {
    async beginTour() {
      await this.listProjects();

      this.tourCallbacks = {
        onFinish: this.setTourCookie,
        onSkip: this.setTourCookie,
      };
      
      this.$tours[ 'saasTour' ].start();
    },

    setTourCookie() {
      const self = this;
      this.$cookies.set( 'close_corsair_saas_tour', true , "1y");
      if(this.redirectPaymentMethods){
        this.$router.push({name: 'addPayment'});
        setTimeout(() => {
          self.pushMessage({
                             type: 'warning',
                             title: 'Credit Card Needed!',
                             text: `Please fill out your credit card information in order to proceed with creating M360API Projects.`
                           });
        }, 500);
      }
    },

    async listProjects() {
      const self = this;

      async function checkSaas() {
        return self.getSendData( {
                                   noLoading: true,
                                   url: '/saas/project',
                                   method: 'get',
                                   params: {
                                     "type": 'saas'
                                   }
                                 } ).then( response => {
          return response && Array.isArray( response ) && response.length > 0;
        } ).catch( error => {
          console.error( error );
          return false;
        } );
      }

      async function checkEnterprise( cb ) {
        return self.getSendData( {
                                   noLoading: true,
                                   url: '/saas/project',
                                   method: 'get',
                                   params: {
                                     "type": 'enterprise'
                                   }
                                 } ).then( response => {
          return response && Array.isArray( response ) && response.length > 0;
        } ).catch( error => {
          console.error( error );
          return false;
        } );
      }

      self.tourOn = true;
      return axios.all( [ checkSaas(), checkEnterprise() ] ).then( data => {
        if(!data.includes(true)){
          self.redirectPaymentMethods = true;
        }
        self.tourOn = false;
        return true;
      } );
    },

    ...mapMutations( {
                       setDrawer: 'SET_DRAWER',
                     } ),
  }
}
</script>
<style lang="scss">
#saasFeatures, #enterpriseFeatures {
  background-color: transparent;
}

#saasFeatures .v-list-item:hover {
  background-color: $secondary !important;
}

#enterpriseFeatures .v-list-item:hover {
  background-color: $accent !important;
}

.v-tour .v-step {
  background-color: #FFA526;
  color: black;
}

.v-tour__target--highlighted {
  -webkit-box-shadow: 0 0 0 4px #FFA526;
  box-shadow: 0 0 0 4px #FFA526;
}
</style>
